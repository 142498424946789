<template>
  <v-data-table
    class="audience-overlap-data-table"
    :headers="tableHeaders"
    :items="tableItems"
  >
    <!-- Show the profile -->
    <template v-slot:item.username="{ item }">
      <profile-chip :platform="model.platform" :data="item.preview" />
    </template>

    <!-- Show the followers count -->
    <template v-slot:item.followers="{ item }">
      {{ nFormatter(item.followers) }}
    </template>

    <!-- Show the unique percentage -->
    <template v-slot:item.unique_percentage="{ item }">
      {{ fPercent(item.unique_percentage * 100) }}
    </template>

    <!-- Show the overlapping percentage -->
    <template v-slot:item.overlapping_percentage="{ item }">
      {{ fPercent(item.overlapping_percentage * 100) }}
    </template>
  </v-data-table>
</template>

<script>
// Import the child components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')

// Export the SFC
export default {
  // Name of the component
  name: "CompareTable",

  // Register the child components
  components: {
    ProfileChip
  },

  // Accept incoming data from parent
  props: {
    model: {
      type: Object,
      required: true
    },
  },

  // Define local data variables
  data: () => ({
    tableHeaders: [
      {
        text: "Username",
        value: "username",
        sortable: false
      },
      {
        text: "Followers",
        value: "followers",
        sortable: true,
      },
      {
        text: "Unique Percentage",
        value: "unique_percentage",
        sortable: true,
        align: "end"
      },
      {
        text: "Overlapping Percentage",
        value: "overlapping_percentage",
        sortable: true,
        align: "end"
      },
    ]
  }),

  // Define the computed properties
  computed: {
    tableItems() {
      return this.model.items.map((item) => ({
        ...item.data,
        preview: item.preview
      }))
    }
  },
}
</script>

<style lang="stylus">
.audience-overlap-data-table thead tr th
  white-space nowrap !important
</style>
