var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"audience-overlap-data-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('profile-chip',{attrs:{"platform":_vm.model.platform,"data":item.preview}})]}},{key:"item.followers",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nFormatter(item.followers))+" ")]}},{key:"item.unique_percentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fPercent(item.unique_percentage * 100))+" ")]}},{key:"item.overlapping_percentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fPercent(item.overlapping_percentage * 100))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }