<template>
  <div class="compare-table compare-table--influencers">
    <div
      id="compareElement"
      ref="compareElement"
      class="compare-table-element--influencers white box-shadow-soft"
    >
      <template v-if="isEverythingReady">
        <div class="box">
          <!-- 0. Profile pictures and names -->
          <v-row class="px-3">
            <v-col cols="3" lg="2" class="px-0 secondary d-flex align-end">
              <div class="text-h6 pl-4 primary--text">
                Overview
              </div>
            </v-col>
            <v-col cols="9" lg="10" class="px-0 py-8 d-flex align-end">
              <v-row class="px-3">
                <v-col
                  :cols="colSize"
                  v-for="(item, index) in computedProfiles"
                  :key="'profile-' + index"
                  class="pl-3 d-flex flex-column align-center"
                >
                  <v-avatar size="50">
                    <img :src="proxyUrl(item.userProfile.picture)" />
                  </v-avatar>

                  <div class="font-weight-bold secondary--text text-body-1 mt-3">
                    {{ item.userProfile.fullname || item.userProfile.username }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- 1: Basic Profile Stats -->
          <!-- a 2x10 grid -->
          <v-row class="px-3">
            <v-col cols="3" lg="2" class="px-0 secondary">
              <v-list dense color="secondary">
                <!-- go through the object and get the text labels -->
                <template
                  v-for="(item, index) in filteredOverviewValues[0]"
                >
                  <v-list-item
                    :key="'overview-text-' + index"
                    class="text-body-2 white--text"
                  >
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                    <v-list-item-action v-if="item.tooltip">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                            small
                          >
                            help
                          </v-icon>
                        </template>
                        <span>
                          {{ item.tooltip }}
                        </span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider
                    v-if="(index + 1) < filteredOverviewValues[0].length"
                    :key="'overview-text-divider-' + index"
                  ></v-divider>
                </template>
              </v-list>
            </v-col>

            <v-col cols="9" lg="10" class="px-0">
              <!-- show equal width 2 or 3 items -->
              <v-row class="px-3">
                <v-col
                  :cols="colSize"
                  v-for="(_, index) in computedProfiles"
                  :key="'stats-' + index"
                  class="px-0"
                >
                  <v-list
                    dense
                    class="text-body-2 font-weight-medium"
                  >
                    <!-- go through the object and get the text labels -->
                    <template
                      v-for="(item, _index) in filteredOverviewValues[index]"
                      v-if="!item.shouldBeHidden"
                    >
                      <v-list-item
                        :key="'overview-value-' + index + '-' + _index"
                        class="px-3 d-flex justify-center secondary--text"
                      >
                        <template v-if="typeof item.value === 'string'">
                          {{ item.value || '-' }}
                        </template>
                        <div
                          v-else
                          class="d-flex flex-column pt-2"
                        >
                          <div
                            v-for="_item in item.value"
                            :key="`${_index}-${_item}`"
                            class="text-center"
                          >
                            {{ _item }}
                          </div>
                        </div>
                      </v-list-item>

                      <v-divider
                        v-if="(_index + 1) < filteredOverviewValues[index].length"
                        :key="'overview-value-divider-' + index + '-' + _index"
                      ></v-divider>
                    </template>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <!-- 2: Audience Section -->
        <div
          v-for="audienceType in audienceTypes"
          :key="'audience-type-' + audienceType"
        >
          <div class="box">
            <v-divider />

            <v-row class="px-3 pt-4">
              <v-col
                cols="3"
                lg="2"
                class="px-0 secondary"
                :class="{ 'pt-6': isDownloading }"
              >
                <div class="text-h6 pl-4 primary--text">
                  {{ audienceTypeToTextMap[audienceType] }}' Audience
                </div>
              </v-col>
              <v-col cols="9" lg="10"></v-col>
            </v-row>

            <!-- 2: Audience Stats -->

            <!-- 2.1: Audience Quality and Notable -->
            <v-row class="px-3">
              <v-col cols="3" lg="2" class="px-0 secondary">
                <v-list dense color="secondary">
                  <!-- go through the object and get the text labels -->
                  <template
                    v-for="(item, index) in audienceValues[0][audienceType].filter((value) => !value.shouldBeHidden)"
                  >
                    <v-list-item
                      :key="'audience-text-' + index"
                      class="text-body-2 white--text"
                    >
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>
                      <v-list-item-action v-if="item.tooltip">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="white"
                              v-bind="attrs"
                              v-on="on"
                              small
                            >
                              help
                            </v-icon>
                          </template>
                          <span>
                            {{ item.tooltip }}
                          </span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider
                      v-if="(index + 1) < audienceValues[0][audienceType].filter((value) => !value.shouldBeHidden).length"
                      :key="'audience-text-divider-' + index"
                    ></v-divider>
                  </template>
                </v-list>
              </v-col>

              <v-col cols="9" lg="10" class="px-0">
                <!-- show equal width 2 or 3 items -->
                <v-row class="px-3">
                  <v-col
                    :cols="colSize"
                    v-for="(_, index) in computedProfiles"
                    :key="'stats-' + index"
                    class="px-0"
                  >
                    <v-list
                      dense
                      class="text-body-2 font-weight-medium"
                    >
                      <!-- go through the object and get the text labels -->
                      <template
                        v-for="(item, _index) in audienceValues[index][audienceType].filter((value) => !value.shouldBeHidden)"
                      >
                        <v-list-item
                          :key="'overview-value-' + index + '-' + _index"
                          class="px-3 d-flex justify-center secondary--text"
                        >
                          {{ item.value || '-' }}
                        </v-list-item>

                        <v-divider
                          v-if="(_index + 1) < audienceValues[index][audienceType].filter((value) => !value.shouldBeHidden).length"
                          :key="'overview-value-divider-' + index + '-' + _index"
                        ></v-divider>
                      </template>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- 2.2: Audience Gender -->
            <v-divider />
            <v-row class="px-3">
              <v-col cols="3" lg="2" class="px-0 secondary">
                <v-list dense color="secondary">
                  <v-list-item
                    class="text-body-2 white--text"
                  >
                    <v-list-item-title>
                      Audience Gender
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                            small
                          >
                            help
                          </v-icon>
                        </template>
                        <span>
                          We determine historical followers gender by analyzing the profile picture, name, text in the profile description (bio) and selfies in recent posts.
                        </span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="9" lg="10">
                <v-row>
                  <!-- for each profile -->
                  <v-col
                    :cols="colSize"
                    v-for="(profile, index) in computedProfiles"
                    :key="'audience-value-' + index + '-' + audienceType + '-' + 'gender'"
                  >
                    <v-list
                      dense
                      class="text-body-2"
                    >
                      <v-list-item class="d-flex justify-center">
                        <gender-split
                          :audience-data="profile.audienceData[audienceType]"
                          :height="150"
                        />
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- 2.3: Audience Age -->
            <v-divider />
            <v-row class="px-3">
              <v-col cols="3" lg="2" class="px-0 secondary">
                <v-list dense color="secondary">
                  <v-list-item
                    class="text-body-2 white--text"
                  >
                    <v-list-item-title>
                      Audience Age
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                            small
                          >
                            help
                          </v-icon>
                        </template>
                        <span>
                          We determine historical followers age by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.
                        </span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="9" lg="10">
                <v-row>
                  <!-- for each profile -->
                  <v-col
                    :cols="colSize"
                    v-for="(profile, index) in computedProfiles"
                    :key="'audience-value-' + index + '-' + audienceType + '-' + 'age'"
                  >
                    <v-list
                      dense
                      class="text-body-2"
                    >
                      <v-list-item class="d-flex justify-center">
                        <age-and-gender
                          :audience-data="profile.audienceData[audienceType]"
                          :height="200"
                        />
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div class="box">
            <!-- 2.4: Audience Countries and cities-->
            <template
              v-for="expandableKey in Object.keys(expandableValues[0])"
              v-if="!expandableValues[0][expandableKey][audienceType].shouldBeHidden"
            >
              <v-divider />
              <v-row
                class="px-3"
                :key="expandableKey"
              >
                <v-col
                  cols="3"
                  lg="2"
                  class="px-0 secondary"
                >
                  <v-list dense color="secondary">
                    <v-list-item
                      class="text-body-2 white--text"
                      @click="isExpanded[audienceType][expandableKey] = isExpanded[audienceType][expandableKey] === 0 ? undefined : 0"
                    >
                      <v-list-item-title>
                        {{ expandableValues[0][expandableKey].title }}
                      </v-list-item-title>

                      <v-list-item-icon>
                        <v-icon color="white">
                          {{ isExpanded[audienceType][expandableKey] === 0 ? 'expand_less' : 'expand_more' }}
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="9" lg="10">
                  <v-row>
                    <!-- for each profile -->
                    <v-col
                      :cols="colSize"
                      v-for="(_, index) in computedProfiles"
                      :key="'audience-value-' + index + '-' + expandableKey + '-expandable'"
                    >
                      <v-list
                        v-if="expandableValues[index][expandableKey][audienceType].data.length"
                        dense
                      >
                        <v-list-item>
                          <v-expansion-panels
                            v-model="isExpanded[audienceType][expandableKey]"
                            accordion
                            flat
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header class="pa-0">
                                <v-list
                                  dense
                                  class="text-body-2"
                                >
                                  <v-list-item class="px-0">
                                    <v-list-item-content>
                                      <v-list-item-title class="d-flex justify-space-between mb-1">
                                        {{ expandableValues[index][expandableKey][audienceType].data[0].x }}

                                        <span class="secondary--text">
                                          {{ fPercent(expandableValues[index][expandableKey][audienceType].data[0].y) }}
                                        </span>
                                      </v-list-item-title>

                                      <v-progress-linear
                                        color="blue"
                                        :value="expandableValues[index][expandableKey][audienceType].data[0].y"
                                      />
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="px-0">
                                <v-list
                                  dense
                                  class="text-body-2 pr-6 pt-0 pl-0"
                                >
                                  <v-list-item
                                    v-for="item in expandableValues[index][expandableKey][audienceType].data.slice(1, 6)"
                                    :key="'audience-value-' + index + '-' + expandableKey + '-expandable-' + item.x"
                                    class="px-0"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title class="d-flex justify-space-between mb-1">
                                        {{ item.x }}

                                        <span class="secondary--text">
                                          {{ fPercent(item.y) }}
                                        </span>
                                      </v-list-item-title>

                                      <v-progress-linear
                                        color="blue"
                                        :value="item.y"
                                      />
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </div>
        </div>

        <!-- 3: Posts -->
        <v-row class="px-3">
          <v-col cols="3" lg="2" class="px-0 secondary pt-12">
            <div class="text-h6 pl-4 primary--text">
              Posts
            </div>
          </v-col>

          <v-col cols="9" lg="10" class="pt-4">
            <div class="d-flex justify-space-between">
              <div>
                <v-tabs v-model="selectedTab.postType">
                  <v-tab>
                    Top
                  </v-tab>
                  <v-tab v-if="shouldShowSponsoredPosts">
                    Sponsored
                  </v-tab>
                </v-tabs>
              </div>

              <v-spacer />

              <div>
                <v-tabs v-model="selectedTab.profile">
                  <v-tab
                    :cols="colSize"
                    v-for="(item, index) in computedProfiles"
                    :key="'profile-tab-' + index"
                    :value="index"
                  >
                    <v-avatar size="30">
                      <img :src="proxyUrl(item.userProfile.picture)" />
                    </v-avatar>
                  </v-tab>
                </v-tabs>
              </div>
            </div>

            <!-- posts container -->
            <div class="mt-4">
              <component
                :is="shouldUseSlider ? 'v-sheet' : 'div'"
                color="transparent"
                class="mx-auto"
              >
                <component
                  :is="shouldUseSlider ? 'v-slide-group' : 'v-row'"
                  :class="shouldUseSlider ? 'pb-6' : ''"
                  :show-arrows="shouldUseSlider"
                >
                  <component
                    :is="shouldUseSlider ? 'v-slide-item' : 'v-col'"
                    v-for="item in postItems"
                    :key="'mobile-post-' + item.post_id"
                    :cols="12"
                    :sm="6"
                    lg="3"
                  >
                    <v-card
                      flat
                      class="box-shadow-soft user-profile-post-item d-flex flex-column"
                      @click="redirectToWeb(item.link)"
                      :class="{ 'mx-4 mb-6': shouldUseSlider }"
                    >
                      <v-img :src="proxyUrl(item.thumbnail)" height="300" contain />

                      <v-card-text class="flex-grow-1">
                        <div class="mb-2">
                          {{ dayjs(item.created).format("LLL") }}
                        </div>

                        <div v-if="item.text">
                          {{ item.text.slice(0, 99) }}...
                        </div>
                      </v-card-text>

                      <v-card-actions class="px-5 py-3 post-card-stats font-weight-bold">
                        <div class="d-flex">
                          <v-icon>thumb_up</v-icon> &nbsp;
                          {{ nFormatter(item.stat.likes) }}
                        </div>

                        <v-spacer></v-spacer>

                        <template v-if="item.stat.views">
                          <div class="d-flex">
                            <v-icon>play_circle</v-icon> &nbsp;
                            {{ nFormatter(item.stat.views) }}
                          </div>

                          <v-spacer></v-spacer>
                        </template>

                        <div class="d-flex">
                          <v-icon>comment_bank</v-icon> &nbsp;
                          {{ nFormatter(item.stat.comments) }}
                        </div>
                      </v-card-actions>
                    </v-card>
                  </component>
                </component>
              </component>
            </div>
          </v-col>
        </v-row>
      </template>
    </div>

    <!-- the download button -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isEverythingReady"
          @click="triggerDownload"
          color="primary"
          v-bind="attrs"
          v-on="on"
          bottom
          fixed
          right
          fab
        >
          <!-- If downloading, show the progress value -->
          <span v-if="isDownloading">
            {{ downloadProgress }}%
          </span>

          <!-- Otherwise -->
          <v-icon v-else>
            download
          </v-icon>
        </v-btn>
      </template>

      <span>
        {{ isDownloading ? 'Generating your report' : 'Download report as PDF' }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
// computed properties through mixins
import userDataFunctions from "@/helpers/userData"
import { getClientID } from "@/helpers/clientHelper"

// Graph Blocks
const GenderSplit = () => import(/* webpackChunkName: "charts-gender-split" */ "@/blocks/user-profile/charts/GenderSplit.vue")
const AgeAndGender = () => import(/* webpackChunkName: "charts-age-gender" */ "@/blocks/user-profile/charts/AgeAndGender.vue")

// Vuetify components for dynamic component:is rendering
import { VRow, VCol, VSheet, VSlideGroup, VSlideItem } from 'vuetify/lib'

/**
 * A helper function to generate the repetitive object array
 *
 * @param {String} value
 * @param {Array} types
 * @returns {Object}
 */
const generateIsExpanded = (value, types) => {
  const items = ["countries", "interests", "cities"]

  const toReturn = {}

  for (const type of types) {
    toReturn[type] = {}

    for (const item of items) {
      toReturn[type][item] = value
    }
  }

  return toReturn
}

// Export the SFC
export default {
  // Name of the component
  name: "CompareTable",

  // Register the children components
  components: {
    GenderSplit,
    AgeAndGender,

    VRow,
    VCol,
    VSheet,
    VSlideGroup,
    VSlideItem,
  },

  // Accept incoming data from the parent
  props: {
    profiles: {
      type: Array,
      required: false,
      default: () => ([])
    },

    platform: {
      type: String,
      required: false,
      default: "instagram"
    },
  },

  // Define local data variables
  data: () => ({
    // render UI once it's done
    isEverythingReady: false,
    isDownloading: false,
    downloadProgress: 0,
    progressHandle: null,
    triggerId: null,

    selectedTab: {
      profile: 0,
      postType: 0
    },
    isExpanded: null,

    // used to reset to state after download
    currentValues: {
      scroll: 0,
      expanded: null
    },

    // and this array will be filled with it,
    postValues: [],
    overviewValues: [],
    audienceValues: [],
    expandableValues: [],
    computedProfiles: [],

    // to show the correct text titles
    audienceTypeToTextMap: {
      followers: "Followers",
      comments: "Commenters",
      likes: "Engagers"
    }
  }),

  // Define readonly data variables
  computed: {
    /**
     * Should the posts be shown in a grid or a slider
     *
     * @returns {Boolean}
     */
    shouldUseSlider() {
      return this.isLargeDevice
    },

    /**
     * Depending on the number of profiles, what should the column size be
     *
     * @returns {Number}
     */
    colSize() {
      return this.profiles.length === 2 ? 6 : 4
    },

    /**
     * Get all the posts from the current selected profile
     *
     * @returns {Array}
     */
    postItems() {
      return this.postValues[this.selectedTab.profile][this.selectedTab.postType]
    },

    /**
     * Get only the overview rows which all 3 profiles have value of
     *
     * @returns {Array}
     */
    filteredOverviewValues() {
      return this.overviewValues.map((item) => item.filter((value) => !value.shouldBeHidden))
    },

    /**
     * Whether or not to show the sponsored posts for the user
     *
     * @returns {Boolean}
     */
    shouldShowSponsoredPosts() {
      return this.postValues.map((item) => item[1]).filter((value) => Boolean(value)).length > 0
    },

    /**
     * Depending on the platform, the sections are different
     *
     * @returns {Array}
     */
    audienceTypes() {
      const map = {
        instagram: ['followers', 'likes'],
        youtube: ['followers', 'comments'],
        tiktok: ['followers', 'likes']
      }

      return map[this.platform]
    }
  },

  // Define local method functions
  methods: {
    /**
     * Compute the row values for all the profiles
     *
     * @returns {void}
     */
    computeOverviewValues() {
      this.overviewValues = this.computedProfiles.map((profile) => {
        return [
          {
            text: this.platform === "youtube" ? "Subscribers" : "Followers",
            value: profile.overviewData.followers
          },
          {
            shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.overviewData.views)).filter((value) => value).length === 0,
            text: "Average Views",
            value: profile.overviewData.views,
            tooltip: "The average sum of views on the last 30 posts."
          },
          {
            shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.overviewData.likes)).filter((value) => value).length === 0,
            text: "Average Likes",
            value: profile.overviewData.likes,
            tooltip: "The average sum of likes on the last 30 posts."
          },
          {
            shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.overviewData.comments)).filter((value) => value).length === 0,
            text: "Average Comments",
            value: profile.overviewData.comments,
            tooltip: "The average sum of comments on the last 30 posts."
          },
          {
            shouldBeHidden: this.platform !== "instagram" || this.computedProfiles.map((_profile) => Boolean(_profile.overviewData.reelsViews)).filter((value) => value).length === 0,
            text: "Average Reel Views",
            value: profile.overviewData.reelsViews,
            tooltip: "The average sum of reel views on the last 30 posts."
          },
          {
            shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.overviewData.engagementRate)).filter((value) => value).length === 0,
            text: "Engagement Rate",
            value: profile.overviewData.engagementRate ? profile.overviewData.engagementRate + '%' : null,
            tooltip: "Average likes divided by followers."
          },
          {
            shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.extraData.nonFollowerLikes)).filter((value) => value).length === 0,
            text: "Engagement from Discovery",
            value: profile.audienceData.extraData.nonFollowerLikes ? profile.audienceData.extraData.nonFollowerLikes.toFixed(2) + '%' : null,
            tooltip: "Percentage of average likes from non followers."
          },
          {
            shouldBeHidden: this.computedProfiles.map((_profile) => Boolean((_profile.userProfile?.interests) || []).length).filter((value) => value).length === 0,
            text: "Influencer Interests",
            value: (profile.userProfile?.interests || []).slice(0, 5).map((item) => item.name) || null,
            tooltip: "We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags."
          },
        ]
      })
    },

    /**
     * Compute the row values for all the profiles
     *
     * @returns {void}
     */
    computeAudienceValues() {
      this.audienceValues = this.computedProfiles.map((profile) => {
        return {
          likes: [
            {
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.extraData.likerCreds)).filter((value) => value).length === 0,
              title: "Audience Quality",
              value: profile.audienceData.extraData.likerCreds ? profile.audienceData.extraData.likerCreds.toFixed(2) + '%' : null,
              tooltip: `To establish the audience credibility score for active audience we take into account factors such as an account's avatar and bio description, number of posts, number of accounts followed vs following ratio. Please note that the audience credibility is based only on active engaged audience (likes) i.e. a high credibility score does not necessarily indicate "real followers" - the account may still have bought fake followers, the engagement rate could be very low but those few engagements are real and therefore the credibility score could still be high. Influencers with genuine audience will attain scores of 80 or above.`
            },
            {
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.extraData.notableLikes)).filter((value) => value).length === 0,
              title: "Notable Engagers",
              value: profile.audienceData.extraData.notableLikes ? profile.audienceData.extraData.notableLikes.toFixed(2) + '%' : null,
              tooltip: `Shows what percentage of engagers are influencers. Notable Engagers for a 0.99 quantile can largely differ from 1% to 20%.`
            }
          ],

          followers: [
            {
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.extraData.followersCreds)).filter((value) => value).length === 0,
              title: "Audience Quality",
              value: profile.audienceData.extraData.followersCreds ? profile.audienceData.extraData.followersCreds.toFixed(2) + '%' : null,
              tooltip: "To establish the followers credibility score for historical followers we take into account factors such as an account’s avatar and bio description, number of posts, number of accounts followed vs following ratio. Influencers with a genuine audience will attain scores of 80 or above."
            },
            {
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.extraData.notableFollowers)).filter((value) => value).length === 0,
              title: "Notable Followers",
              value: profile.audienceData.extraData.notableFollowers ? profile.audienceData.extraData.notableFollowers.toFixed(2) + '%' : null,
              tooltip: "Shows what percentage of followers are influencers. Notable Followers for a 0.99 quantile can largely differ from 1% to 20%."
            }
          ],

          comments: [
            {
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.extraData.likerCreds)).filter((value) => value).length === 0,
              title: "Audience Quality",
              value: profile.audienceData.extraData.likerCreds ? profile.audienceData.extraData.likerCreds.toFixed(2) + '%' : null,
              tooltip: `To establish the audience credibility score for active audience we take into account factors such as an account's avatar and bio description, number of posts, number of accounts followed vs following ratio. Please note that the audience credibility is based only on active engaged audience (likes) i.e. a high credibility score does not necessarily indicate "real followers" - the account may still have bought fake followers, the engagement rate could be very low but those few engagements are real and therefore the credibility score could still be high. Influencers with genuine audience will attain scores of 80 or above.`
            },
            {
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.extraData.notableLikes)).filter((value) => value).length === 0,
              title: "Notable Engagers",
              value: profile.audienceData.extraData.notableLikes ? profile.audienceData.extraData.notableLikes.toFixed(2) + '%' : null,
              tooltip: `Shows what percentage of engagers are influencers. Notable Engagers for a 0.99 quantile can largely differ from 1% to 20%.`
            }
          ],
        }
      })
    },

    /**
     * Compute the row values for all the profiles
     *
     * @returns {void}
     */
    computeExpandableValues() {
      this.expandableValues = this.computedProfiles.map((profile) => {
        return {
          countries: {
            title: "Audience Countries",
            likes: {
              data: profile.audienceData.likes.country,
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.likes.country.length)).filter((value) => value).length === 0
            },
            comments: {
              data: profile.audienceData.comments.country,
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.comments.country.length)).filter((value) => value).length === 0
            },
            followers: {
              data: profile.audienceData.followers.country,
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.followers.country.length)).filter((value) => value).length === 0
            },
          },
          cities: {
            title: "Audience Cities",
            likes: {
              data: profile.audienceData.likes.city,
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.likes.city.length)).filter((value) => value).length === 0
            },
            comments: {
              data: profile.audienceData.comments.city,
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.comments.city.length)).filter((value) => value).length === 0
            },
            followers: {
              data: profile.audienceData.followers.city,
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.followers.city.length)).filter((value) => value).length === 0
            }
          },
          interests: {
            title: "Audience Interests",
            likes: {
              data: profile.audienceData.likes.interests.map((item) => ({
                x: item.label,
                y: item.weight
              })),
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.likes.interests.length)).filter((value) => value).length === 0
            },
            comments: {
              data: profile.audienceData.comments.interests.map((item) => ({
                x: item.label,
                y: item.weight
              })),
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.comments.interests.length)).filter((value) => value).length === 0
            },
            followers: {
              data: profile.audienceData.followers.interests.map((item) => ({
                x: item.label,
                y: item.weight
              })),
              shouldBeHidden: this.computedProfiles.map((_profile) => Boolean(_profile.audienceData.followers.interests.length)).filter((value) => value).length === 0
            }
          }
        }
      })
    },

    /**
     * Compute the row values for all the profiles
     *
     * @returns {void}
     */
    computePostValues() {
      this.postValues = this.computedProfiles.map((profile) => {
        return [
          profile.userProfile.top_posts,
          profile.userProfile.commercial_posts
        ]
      })
    },

    /**
     * Trigger download network request
     *
     * @returns {void}
     */
    async triggerDownload() {
      if (this.isDownloading) return

      // Reset the download progress
      this.isDownloading = true
      this.downloadProgress = 0
      this.triggerId = String(Date.now())

      // Every 1 second, move the progress bar a bit more
      this.progressHandle = window.setInterval(this.moveProgress, 600)

      // Create a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId, { root: true })

      try {
        await axios({
          url: `/api/influencers-tie-breaker/${this.platform}/report`,
          method: "POST",
          data: {
            // Pass the current tab's ID to make sure only we download the file once
            clientId: getClientID(),
            // Pass the trigger origin ID for tracking progress
            triggerId: this.triggerId,
            // The array of all the usernames
            accounts: this.computedProfiles.map((profile) => profile.userProfile.user_id)
          }
        })
      } catch (error) {
        // log using the helper function
        logger({ type: "Network Error", error })

        // Show a message
        this.$store.dispatch("toasts/add", { text: "An error occurred, please try again" })
      } finally {
        this.$store.dispatch("loaders/remove", loaderId, { root: true })
      }
    },

    /**
     * Update the value for progress bar
     *
     * @returns {void}
     */
    moveProgress() {
      if (this.downloadProgress >= 93) {
        window.clearInterval(this.progressHandle)
      }
      else {
        this.downloadProgress += 4
      }
    },

    /**
     * Stop the download progress
     *
     * @param {Event} event
     * @returns {void}
     */
    stopDownloadProgressBar(event) {
      // If the triggerId matches
      if (event.detail.data.misc.triggerId === this.triggerId) {
        this.isDownloading = false
        this.downloadProgress = 0
        window.clearInterval(this.progressHandle)
      }
    }
  },

  /**
   * As soon as the component is ready to be rendered
   *
   * @returns {void}
   */
  mounted() {
    // Go and populate userData one by one
    for (const profile of this.profiles) {
      // Now compute the rest of the values
      this.computedProfiles.push({
        userProfile: profile.data.data.user_profile,
        totalValues: userDataFunctions.computeTotalValues(profile.data),
        overviewData: userDataFunctions.computeOverviewData(profile.data),
        audienceData: userDataFunctions.computeAudienceData(profile.data),
        engagementData: userDataFunctions.computeEngagementData(profile.data)
      })
    }

    // Now compute overview values that'll be shown in a list view
    this.computePostValues()
    this.computeOverviewValues()
    this.computeAudienceValues()
    this.computeExpandableValues()

    // Generate the default value
    this.isExpanded = generateIsExpanded(false, this.audienceTypes)

    // Render the UI components/blocks
    this.isEverythingReady = true
  },

  /**
   * As soon as the component's data is ready
   *
   * @returns {void}
   */
  created() {
    // Keep listening for a download event
    window.addEventListener("ReadyToDownload", this.stopDownloadProgressBar)
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the event listener
    window.removeEventListener("ReadyToDownload", this.stopDownloadProgressBar)
  }
}
</script>

<style lang="stylus">
.compare-table--influencers
  min-width 1000px
  border-radius 0.25em
  overflow hidden

  .pdf-announcement-container
    position fixed
    z-index 30000
    height 100%
    width 100%
    left 0
    top 0

.compare-table-element--influencers
  // to attain max width possible
  .v-expansion-panel-content__wrap
    padding 0 !important
</style>
